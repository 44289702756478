import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Container, Row, Col, Breadcrumb } from "react-bootstrap"
import axios from "axios"
import settings from "../settings"
import {  v4 } from 'uuid';

import "./itinerary-view.scss"
import Seo from "../components/seo"

export default function Post({ data }) {
  const content = JSON.parse(data.mysqlPages.content)
  return (
    <Layout>
      <Seo title={content.primaryInfo.title + "| iwant2explore.com | visa2explore"} description={content.primaryInfo.metadescription} keywords={content.primaryInfo.metakeyword} />
      <div className="section">
        <Container fluid>
          <Container className="post">
            <Row className="justify-content-md-center">
              <Col lg={12}>
                <h1>{content.primaryInfo.title}</h1>

                {content.pageContent.map((block, i) => {
                  if (block.type === "CONTENT") {
                    return <ContentBlock key={"content_block_" + i} data={block} />
                  } else if (block.type === "IMAGE") {
                    return <ImageBlock key={"image_block_" + i} data={block} />
                  }
                })}

              </Col>

            </Row>
          </Container>
        </Container>
      </div>
    </Layout>
      )
}

export const query = graphql`
query ($uri: String!) {
  mysqlPages(slug: {eq:$uri}) {
    content
    slug
  }
}
`
const ContentBlock = ({ data }) => {
    return (
      <div className="contentblock">
        <h2>{data.heading}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </div>
    )
  }

function ImageBlock({ data }) {
  return (
    <Row className="image-block">
      {data.images.map((image, i) => {
        return (
          <Col lg={6} key={"image_" + i}>
            <img src={settings.IMAGE_URL + image.imageData.publicUrl+".webp"}  alt={image.imageData.caption}/>
          </Col>
        )
      })}
    </Row>
  )
}
